const bohdan = {
    location: "New York, NY",
    contacts: {
        email: "bohdankhv@gmail.com",
        linkedin: "https://www.linkedin.com/in/bohdan-khvorostovskyi/",
        github: "https://github.com/BohdanKhv",
        leetcode: "https://leetcode.com/BohdanKhvorostovskyi/",
        googleplay: "https://play.google.com/store/apps/dev?id=6724538733217670707",
        instagram: "https://www.instagram.com/bohdan.khv/"
    },
    resume: "/Bohdan_Khvorostovskyi_CV.pdf",
    interests: [
        "Programming",
        "Design",
        "Music",
    ],
    education: "Master's degree in Agriculture Engineering, Podilskyi Agrarian Technical University, Ukraine",
    skills: [
        "React.js",
        "MongoDB",
        "Node.js",
        "Express.js",
        "Redux",
        "React Native",
        "HTML",
        "CSS",
        "JavaScript",
        "GitHub",
        "git"
    ]
}

const projects = [
    {
        title: "Board Game Community Platform",
        url: "https://bggrid.com",
        github: "https://github.com/BohdanKhv/bggrid.com",
        stack: [
            "React.js",
            "Node.js",
            "Express.js",
            "MongoDB",
            "Redux",
            "HTML",
            "CSS",
        ],
        image: "bggrid.jpg",
    },
    {
        title: "Emplorex Business Suite",
        url: "https://emplorex.com",
        stack: [
            "React.js",
            "Node.js",
            "Express.js",
            "MongoDB",
            "Redux",
            "HTML",
            "CSS",
        ],
        image: "emplorex.jpg",
    },
    {
        title: "InCrew.cafe Job Board",
        url: "https://increw.cafe",
        stack: [
            "React.js",
            "Node.js",
            "Express.js",
            "MongoDB",
            "Redux",
            "HTML",
            "CSS",
        ],
        image: "increw.jpg",
    },
    {
        title: "Diva Rice Website",
        github: "https://github.com/BohdanKhv/divarice",
        url: "https://divarice.com",
        stack: [
            "React.js",
            "HTML",
            "CSS",
        ],
        image: "divarice.jpg",
    },
    {
        title: "Arvin Garay Portfolio",
        github: "https://github.com/BohdanKhv/arvingaray.com",
        url: "https://arvingaray.com",
        stack: [
            "React.js",
            "HTML",
            "CSS",
        ],
        image: "arvingaray.jpg",
    },
    {
        title: "Aaron Lopatin Portfolio",
        github: "https://github.com/BohdanKhv/aaronlopatin.com",
        url: "https://aaronlopatin.com",
        stack: [
            "React.js",
            "HTML",
            "CSS",
        ],
        image: "aaronlopatin.jpg",
    },
    {
        title: "Track cycle expenses",
        github: "https://github.com/BohdanKhv/cyclepay",
        url: "https://play.google.com/store/apps/details?id=com.cyclepay",
        stack: [
            "React Native",
            "Redux",
            "Redux Persist",
        ],
        image: "cyclepay.jpg",
    },
    {
        title: "Serverless stock market portfolio builder",
        url: "http://stokin.s3-website.us-east-2.amazonaws.com",
        github: "https://github.com/BohdanKhv/market-watch",
        stack: [
            "React.js",
            "Redux",
            "HTML",
            "CSS",
        ],
        image: "market-watch.jpg",
    },
    {
        title: "Typing game",
        url: "http://typing-g.s3-website.us-east-2.amazonaws.com/",
        github: "https://github.com/BohdanKhv/typing-game",
        stack: [
            "React.js",
            "Redux",
            "HTML",
            "CSS",
        ],
        image: "typing-game.jpg",
    },
    {
        title: "Drawing app",
        url: "http://react-drawing-app.s3-website.us-east-2.amazonaws.com",
        github: "https://github.com/BohdanKhv/react-drawing-app",
        stack: [
            "React.js",
            "Redux",
            "HTML",
            "CSS",
        ],
        image: "drawing-app.jpg",
    },
    {
        title: "Math Game",
        url: "http://math-g.s3-website.us-east-2.amazonaws.com",
        github: "https://github.com/BohdanKhv/math-game",
        stack: [
            "React.js",
            "Redux",
            "HTML",
            "CSS",
        ],
        image: "math-game.jpg",
    },
    {
        title: "Artificial life",
        url: "http://artificial-life.s3-website.us-east-2.amazonaws.com",
        github: "https://github.com/BohdanKhv/Artificial-Life",
        stack: [
            "React.js",
            "HTML",
            "CSS",
        ],
        image: "artificial-life.jpg",
    },
    {
        title: "Keypad instrument",
        url: "http://virtual-instrument.s3-website.us-east-2.amazonaws.com",
        github: "https://github.com/BohdanKhv/keypad-instrument",
        stack: [
            "React.js",
            "HTML",
            "CSS",
        ],
        image: "keypad-instrument.jpg",
    },
    {
        title: "Code snapshot",
        url: "http://snapcode.s3-website.us-east-2.amazonaws.com",
        github: "https://github.com/BohdanKhv/code-snapshot",
        stack: [
            "React.js",
            "HTML",
            "CSS",
        ],
        image: "code-snapshot.jpg",
    },
    {
        title: "Calculator",
        url: "http://react-js-calculator.s3-website.us-east-2.amazonaws.com/",
        github: "https://github.com/BohdanKhv/react-js-calculator",
        stack: [
            "React.js",
            "HTML",
            "CSS",
        ],
        image: "calculator.jpg",
    },
    {
        title: "Virtual keyboard",
        url: "http://react-keyboard.s3-website.us-east-2.amazonaws.com/",
        github: "https://github.com/BohdanKhv/react-js-virtual-keyboard",
        stack: [
            "React.js",
            "HTML",
            "CSS",
        ],
        image: "virtual-keyboard.jpg",
    },
    {
        title: "Thallify",
        url: "http://thallify.com",
        github: "https://github.com/BohdanKhv/thallify.com",
        stack: [
            "React.js",
            "Redux",
            "Spotify API",
            "HTML",
            "CSS",
        ],
        image: "thallify.jpg",
    },
    {
        title: "Thall",
        url: "http://thall.us",
        github: "https://github.com/BohdanKhv/thall.us",
        stack: [
            "React.js",
            "HTML",
            "CSS",
        ],
        image: "thall.jpg",
    },
    {
        title: "Old portfolio",
        url: "http://spaceguy.org.s3-website.us-east-2.amazonaws.com",
        github: "https://github.com/BohdanKhv/my-website-portfolio",
        stack: [
            "JavaScript",
            "HTML",
            "CSS",
        ],
        image: "old-portfolio.jpg",
    },
    {
        title: "Faskme",
        github: "https://github.com/BohdanKhv/FASK.ME",
        stack: [
            "React.js",
            "Redux",
            "Node.js",
            "Express.js",
            "MongoDB",
            "HTML",
            "CSS",
        ],
        image: "faskme.jpg",
    },
    {
        title: "Schedule app",
        github: "https://github.com/BohdanKhv/mern-schedule-app",
        stack: [
            "React.js",
            "Redux",
            "Node.js",
            "Express.js",
            "MongoDB",
            "HTML",
            "CSS",
        ],
        image: "scheduler.jpg",
    },
    {
        title: "Link tree 'clone'",
        github: "https://github.com/BohdanKhv/mern-linktree-clone",
        stack: [
            "React.js",
            "Redux",
            "Node.js",
            "Express.js",
            "MongoDB",
            "HTML",
            "CSS",
        ],
        image: "linktree.jpg",
    },
    {
        title: "Movie trailer app",
        github: "https://github.com/BohdanKhv/movie_trailer_app",
        stack: [
            "React.js",
            "Redux",
            "HTML",
            "CSS",
        ],
        image: "movie-trailer.jpg",
    },
    {
        title: "Book app",
        github: "https://github.com/BohdanKhv/react-node-book-app",
        stack: [
            "React.js",
            "Redux",
            "Node.js",
            "Express.js",
            "Cheerio.js",
            "HTML",
            "CSS",
        ],
        image: "book.jpg",
    },
    {
        title: "Live chat app",
        github: "https://github.com/BohdanKhv/socketio-nodejs-react-chat",
        stack: [
            "React.js",
            "Node.js",
            "Express.js",
            "Socket.io",
            "HTML",
            "CSS",
        ],
        image: "chat.jpg",
    },
    {
        title: "Tic-Tac-Toe live game",
        github: "https://github.com/BohdanKhv/tiktaktoe-socket-io",
        stack: [
            "React.js",
            "Socket.io",
            "Node.js",
            "HTML",
            "CSS",
        ],
        image: "tiktaktoe.jpg",
    },
    {
        title: "Crypto Wallet",
        github: "https://github.com/BohdanKhv/crypto-wallet-react-native-app",
        stack: [
            "React Native",
            "Redux",
        ],
        image: "crypto-wallet.jpg",
    },
    {
        title: "Boba milk tea",
        github: "https://github.com/BohdanKhv/bobamilktea-react-native-app",
        stack: [
            "React Native",
            "Redux",
        ],
        image: "bobamilktea.jpg",
    },
    {
        title: "Online Learning",
        github: "https://github.com/BohdanKhv/online-learning-react-native-app",
        stack: [
            "React Native",
            "Redux",
        ],
        image: "online-learning.jpg",
    },
    {
        title: "Square Project RPG",
        deleted: true,
        stack: [
            "Unity",
            "C#",
        ],
        image: "squareprojectrpg.jpg",
    },
    {
        title: "Square Draggers",
        deleted: true,
        stack: [
            "Unity",
            "C#",
        ],
        image: "squaredraggers.jpg",
    },
    {
        title: "Comet Rush",
        deleted: true,
        stack: [
            "Unity",
            "C#",
        ],
        image: "cometrush.jpg",
    },
    {
        title: "Space Jump",
        deleted: true,
        stack: [
            "Unity",
            "C#",
        ],
        image: "spacejump.jpg",
    },
    {
        title: "My Cards",
        deleted: true,
        stack: [
            "React Native",
            "Python",
            "Django",
            "MySQL",
        ],
        image: "mycards.jpg",
    },
]

export {
    bohdan,
    projects,
}